/* Contains button styles */
/* button {
  display: inline-block;
  border: none;
  padding: 1rem 1rem;
  margin: 0;
  text-decoration: none;
  background: var(--primary-color);
  color: white;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
} */

/* button:focus {
  outline: none;
}

button:enabled:active {
  transform: scale(0.99);
}

button:disabled,
button[disabled] {
  cursor: default;
  padding: 0.8rem 0.8rem;
  color: var(--primary-color);
  opacity: 0.5;
  background: transparent;
  border: 0.2rem solid var(--primary-color);
} */

.common-btn {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #222222;
  width: 16.25rem;
  height: 3.75rem;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: #36C3DF;
  border: none;
  cursor: pointer;
}

.common-btn a {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-solution-btn {
  color: #36C3DF;
  border: 1px solid #36C3DF;
  background-color: transparent;
}

.learn-more-btn {
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  background-color: transparent;
  margin: 0 auto;
}

.dark-blue-btn {
  background-color: #1F6DFF;
  color: #FFFFFF;
}

.btn-link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plf-learn-more-btn {
  width: 16.25rem;
  height: 3.75rem;
  border: 1px solid #444444;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #444444;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: transparent;
}

.plf-learn-more-btn a {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 640px) and (max-width: 940px) {
  .common-btn {
    width: 13rem;
  }
}