/* Contains jumbotron css stuff */


.jumbotron {
  width: 100%;
  /* height: 80vh; */
  /* padding-bottom: 16rem; */


  background-image: url('../../../static/background-landing.png');
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
}

.jumbotron-content-logo {
  position: relative;
  width: 20rem;

  left: 25%;
  top: 15%;
  transform: translate(-50%, -50%);

  text-align: center;
  pointer-events: none;
  z-index: 2;
}

.jumbotron-content {
  position: absolute;
  width: 30rem;

  left: 50%;
  top: 30%;
  /* transform: translate(-50%, -50%); */

  text-align: center;
  background: rgba(255, 255, 255, 0.3);
  pointer-events: none;
  z-index: 2;
}

.jumbotron-content h1 {
  font-weight: 500;
  font-size: 3.5rem;
}

.jumbotron-content h1 b {
  font-weight: 700;
  color: var(--primary-color);
}

.jumbotron-content h2 {
  font-weight: 300;
  font-size: 1.125rem;
}

.jumbotron-content p {
  font-weight: 400;
  color: var(--medium-grey);
  font-size: 1rem;
}

.typed-row {
  width: 100%;
  height: 10vh;
  margin: 0 auto;

  background: var(--primary-darker-color);
  color: white;

  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  font-family: monospace;
  font-family: "Open Sans";

  display: flex;
  justify-content: center;
  align-items: center;
}

.typed-container {
  display: inline;
}

#typed {
  display: inline;
}

/* responsive design overrides */
/* for phones only */
@media (max-width: 639px) {
  .typed-container {
    font-size: 1.4rem;
  }
  .jumbotron {
    padding-bottom: 0rem;
  }
}

/* for tablet portrait up */
@media (min-width: 600px) {
}

/* for tablet landscape up */
@media (min-width: 900px) {
}

/* for desktop up */
@media (min-width: 1200px) {
}
