/* Contains navbar css stuff */

.nav-bar-home {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 6%;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  transition: all 0.3s ease-in-out;
}

.title-bar-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.nav-bar-main {
  display: none;
  visibility: hidden;
  box-sizing: border-box;
  /* position: fixed; */
  top: 0;
  width: 100%;
  padding: 0.8rem 0.8rem;

  /* display: flex; */
  flex-direction: column;

  z-index: 10000;

  transition: all 0.3s ease-in-out;
}

.partner-button {
  background: #002d3e;
}

.partner-button:hover {
  color: var(--new-pri-color);
  border: 0.2rem solid var(--new-pri-color-alt);
  background-color: white;

  /* color: var(--new-pri-color-alt);
  border: 0.2rem solid var(--new-pri-color); */
}

.nav-bar {
  /* display: none; */
  /* visibility: hidden; */
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0.8rem 0.8rem;

  display: flex;
  flex-direction: column;

  z-index: 10000;

  transition: all 0.3s ease-in-out;
}

.nav-bar svg {
  width: 6.5rem;
  fill: var(--primary-color);
}

.title-bar {
  display: flex;
  margin-left: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-sticky {
  display: flex;
  visibility: visible;
  position: fixed;
  background: #000000;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-transform: uppercase;
  padding: 0 6%;
}

.nav-links-home {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #EEEEEE;
  margin-left: auto;
  height: 5.35rem;
  line-height: 5.35rem;
}

.nav-links {
  margin-left: auto;
  margin-bottom: 0rem;
  height: 5.35rem;
  line-height: 5.35rem;
}

.nav-links li, .nav-links-home li {
  display: inline-block;
  margin: 0 4.5rem 0 0;
  
  /* removing list styling */
  list-style: none;
  color: #EEEEEE;
  cursor: pointer;
}

.nav-links a {
  text-decoration: none;
  color: #EEEEEE;
  height: 100%;
  display: block;
}

.nav-links-home a {
  text-decoration: none;
}

#topRequestBtnMobile {
  display: none;
}

.hamburger-button {
  display: none;
  font-size: 1.2rem;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.hamburger-button:hover {
  padding: 0;
}

/* Side nav styling */
.side-nav {
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  text-align: center;
  margin-top: 1.5rem;
}

.side-nav-group h2 {
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: lowercase;
  margin: 0.5rem 0 1rem 0;
}

.side-nav-group ul {
  margin: 0;
  padding: 0;
}

.side-nav-group li {
  margin: 0.5rem 0;
  list-style: none;
  font-size: 1.2rem;
  font-weight: 300;
}

.side-nav-bar a:before {
  background-color: white;
}

.side-nav-group hr {
  margin: 1rem auto 0.5rem auto;
  color: white;
  width: 30vw;
}

.side-nav-bar .button-group {
  margin-top: 2rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

.side-nav-bar .contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin: auto auto 1rem auto;
  line-height: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
}

.side-nav-bar .contact span {
  margin: 0.2rem 0;
}

.side-nav-bar .socials {
  /* margin: 1rem auto 1rem 2rem; */
  margin: 1rem auto auto auto;
}

.side-nav-bar .socials li {
  font-size: 2.5rem;
  margin: 0.3rem 0.4rem 0rem 0.4rem;
}

.side-nav-bar .socials li:hover {
  font-size: 2.7rem;
  margin: 0rem 0.31rem 0rem 0.31rem;
}

/* Side bar enable display */
.side-nav-bar {
  height: 100%;
  color: white;
}

.side-nav-bar svg {
  fill: white;
}

.side-nav-bar .side-nav {
  display: flex;
}

.side-nav-bar button {
  background: white;
  color: black;
}

.button-group a:before {
  background-color: transparent;
}

.side-nav-bar button:hover,
.side-nav-bar button:focus {
  border-color: white;
  background: transparent;
  color: white;
}

/* responsive design overrides */
/* for phones only */
@media (max-width: 639px) {
  .nav-links {
    display: none;
  }

  .nav-links-home {
    display: none;
  }

  .partner-button {
    display: none;
  }

  .hamburger-button {
    display: initial;
  }

  #topRequestBtnMobile {
    display: initial;
    color: #222222;
    background-color: #36C3DF;
  }

  .nav-bar-home {
    display: none;
  }

  #topRequestBtn {
    display: none;
  }
}

/* for tablet portrait up */
@media (min-width: 600px) {
}

/* for tablet landscape up */
@media (min-width: 900px) {
}

/* for desktop up */
@media (max-width: 1200px) {
  .title-bar-home {
    margin: 0;
  }

  .title-bar {
    margin: 0;
  }

  .nav-links {
    margin: 0 auto;
  }

  .nav-links li, .nav-links-home li {
    margin: 0 1rem 0 0;
  }
}

@media (min-width: 640px) and (max-width: 940px) {
  .nav-links-home {
    font-size: 0.8em;
  }

  .nav-links {
    font-size: 0.9em;
    padding-left: 1rem;
  }

  .partner-button {
    display: none;
  }

  .nav-sticky, .nav-bar-home {
    padding: 0 1rem;
  }
}