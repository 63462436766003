.contact-content-container input, .contact-content-container textarea, .contact-content-container select {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 2.25rem;
  color: #FFFFFF;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 1.063rem;
}

.contact-lbl {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 3.188rem;
  color: #444444;
}

.contact-info, .contact-form {
  padding: 0 5.625rem;
}

.contact-content-container {
  display: grid;
  grid-template-columns: 50% 50%;
}

.contact-content-container select {
  width: 100%;
  height: 3.75rem;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../../../static/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
}

.contact-form {
  background: linear-gradient(180deg, #2D3745 0%, #222222 100%), linear-gradient(180deg, #164CB0 0%, #00D5FF 100%), #C4C4C4;
}

.contact-txt {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 2.25rem;
  color: #333333;
}

.underline-txt {
  text-decoration: underline;
}

.font-28 {
  font-size: 1.75rem;
  line-height: 2.125rem;
}

.mg-24 {
  margin: 1.5rem 0;
}

textarea:focus {
  outline: none;
}

.flex {
  display: flex;
}

.form-control:focus {
  color: #FFFFFF !important;
  background-color: inherit !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-validated :invalid {
  border-color: #DC3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-validated :invalid~.invalid-text {
  display: block;
}

.invalid-text {
  display: none;
}

.form-check-input[type="checkbox"] {
  width: 2.375rem;
  height: 2.375rem;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 1.25rem 0 0;
  cursor: pointer;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%2336C3DF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

/* responsive design overrides */
/* for phones only */
@media (max-width: 639px) {
  .contact .map {
    width: 90vw;
    height: 90vw;
  }

  .map {
    width: 80vw;
    height: 90vw;
  }

  .contact-info {
    margin-top: 2rem;
    font-size: 1.1rem;
    line-height: 1.6rem;
  }

  .contact .text-form {
    width: 80vw;
    margin-bottom: 1rem;
  }

  .form h2 {
    font-size: 1.4rem;
  }
}

@media (max-width: 1024px) {
  .contact-content-container {
      grid-template-columns: 100%;
  }
}