.text-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background-color: var(--input-grey);
  border-radius: 0.4rem;

  margin: 0em;
  padding: 0.5em 1em;

  font-weight: 700;
  font-size: 1rem;

  list-style: none;
}

.text-form input {
  width: 100%;
  height: 100%;

  -webkit-appearance: none;

  outline: none;
  border: none;
  background-color: transparent;

  font-weight: 300;
  font-size: 0.9rem;
  text-indent: 0.5em;
}

.text-form textarea {
  width: 100%;
  height: 100%;

  outline: none;
  border: none;
  background-color: transparent;

  font-weight: 300;
  font-size: 0.9rem;
}

::placeholder {
  color: var(--text-color);
  opacity: 0.5;
  font-weight: 300;
  font-size: 0.9rem;
}
