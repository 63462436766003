/* Contains footer css stuff */

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #222222;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  padding: 5rem;
  justify-content: space-between;
  gap: 2rem;
  margin-top: -3rem;
  clear: both;
}

.footer-item {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
}

.footer-nav {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 31px;
  color: #FFFFFF;
}

.footer-txt-container {
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 14px;
  line-height: 1.5rem;
  color: #FFFFFF;
}

.nav-links-footer {
  padding: 0 !important;
}

.nav-links-footer li {
  list-style: none;
  line-height: 2.5rem;
}

.new-bottom-text {
  margin: 1rem;
  font-size: 0.8em;
  font-weight: 300;
}

footer {
  background: var(--primary-darker-color);
  color: white;
  padding: 0rem 2rem;

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  justify-content: flex-start;
  align-items: center;
}

.new-footer {
  /* flex-direction: column; */
  justify-content: flex-start;
  /* align-items: center; */
}

.bottom-text {
  margin: 1rem;
  font-size: 0.6rem;
  font-weight: 300;
}

footer a:before {
  height: 1px;
  background-color: white;
}

.bottom-text a:before {
  height: 1px;
  background-color: white;
}

.footer-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.footer-group {
  margin: 3rem 3rem;
}

.footer-group h1 {
  font-weight: 300;
  font-size: 0.7rem;
  text-transform: uppercase;

  margin-bottom: 1rem;
}

footer svg {
  fill: white;
  width: 6.5rem;
  margin-top: 2rem;
  margin-right: -1rem;
}

.footer-group ul {
  margin: 0;
  padding: 0;
}

.footer-group li {
  margin: 0.5rem 0;
  padding: 0;
  list-style: none;

  font-weight: 600;
  font-size: 0.9rem;
}

.us {
  margin-left: auto;
}

.socials {
  padding: 0;
}

.dismiss-socials {
  margin: 0;
}

.socials li {
  margin: 0rem 0.4rem 0rem 0.4rem;
  display: inline-flex;
  list-style: none;
  font-size: 1.6rem;

  transition: 0.3s font-size ease-in-out, 0.3s margin ease-in-out;
}

.socials li:hover {
  font-size: 1.8rem;
  margin: 0rem 0.31rem 0rem 0.31rem;
  cursor: pointer;
}

/* responsive design overrides */
@media (max-width: 710px) {
  .footer {
    flex-direction: column;
  }

  .footer-item img{
    max-width: 20rem;
  }
}
