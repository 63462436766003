/* Contains base css stuff */

:root {
  font-size: 16px;

  /* Defining colors */
  --primary-color: #000675;
  --primary-darker-color: #00044d;
  --accent-color: #1d228f;
  --medium-grey: #a3a3a3;
  --alt-sub: #ffffff;
  --input-grey: #fafafa;
  --light-grey: #fcfcfc;
  --darker-grey: #f1f1f1;
  --text-color: #3c3744;
  --back-color: #6f40bb;
  --maximum-blue: #53A2BE;
  --new-pri-color: #002d3e;
  --new-pri-color-alt: #0ac8e6;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
}

::selection {
  background: var(--accent-color); /* WebKit/Blink Browsers */
  color: white;
}

html {
  height: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  color: var(--text-color);
  /* color: var(--light-grey); */
  height: 100%;
}

.hamburger-button {
    background-color: transparent;
    color: white;
    border: none;
}

.particlesjs-content {
  position: relative;
}

.hamburger-button span{
  font-size: 1.125rem;
}

.on-mobile {
  display: none;
}

.on-desktop {
  display: block;
}

.main-container {
  margin-top: 2rem;
  min-height: 100%;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 6rem;
  align-items: center;
}

.content-container img {
  width: 80%;
  height: auto;
}

.content-container-port {
    display: grid;
    grid-template-columns: 60% 40%;
}

.content-item {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

.content-container-port .content-item {
    padding: 8rem;
}

.content-item .content-text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5;
    color: #222222;
}

.content-item .content-text li {
    margin-left: 2rem;
    margin-bottom: 0.5rem;
}

.plf-section-lbl {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 2rem;
    line-height: 1.25;
    color: #444444;
}

.plf-section-sub-lbl-28 {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 1.75rem;
    line-height: 1.25;
    color: #176BA0;
    font-weight: 700;
}

.plf-section-sub-lbl-24 {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.5;
    color: #176BA0;
    font-weight: 700;
}

.plf-learn-more-btn {
    width: 16.25rem;
    height: 3.75rem;
    border: 1px solid #444444;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: #444444;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: transparent;
}

.wrapper {
  overflow: auto;
  padding-bottom: 3rem;
}

h1 {
  margin: 0px;
  padding: 0px;
}

a {
  position: relative;
  text-decoration: none;
  color: inherit;
}

/* a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--text-color);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
} */

a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

a.button:before {
  background-color: transparent;
}

.padding-lr {
    padding-left: 4rem;
    padding-right: 4rem;
}

.symbol-target {
  padding-top: 40px;
  margin-top: -40px;
  width: 1px; /* '0' will not work for Opera */
  display: inline-block;
}

.section, .section-alt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 2rem 0rem;
}

.section h1 {
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--primary-color);
}


.section .subtitle {
  font-weight: 400;
  color: var(--medium-grey);
  font-size: 1rem;
  text-align: center;
  line-height: 1.4rem;

  margin-top: 0.4rem;
  width: 40%;
}

.section-alt .subtitle {
  font-weight: 400;
  color: var(--alt-sub);
  font-size: 1rem;
  text-align: center;
  line-height: 1.4rem;

  margin-top: 0.4rem;
  width: 40%;
}

.padding-title {
  padding-top: 80px;
}

.vh-70 {
  height: 45rem;
}

.vh-40 {
  height: 20rem;
}

.vh-30 {
  height: 30vh;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

.none-center-row {
  justify-content: initial;
}

.list-bullet {
  padding-left: 4rem;
}

.connect .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1.5rem;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.primary-color {
  color: var(--primary-color);
}

.grey {
  background: var(--light-grey);
}

.darker-grey {
  background: var(--darker-grey);
}

.black {
  background: black;
}

.purple {
  background: var(--back-color);
}

.blue-max {
  background: var(--maximum-blue);
}

.alt-color {
  color: white;
}

.bg-alt-color {
  background: linear-gradient(to bottom left, #53a2be 24%, #000099 110%);
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px; /* 5px rounded corners */
  padding: 1rem 2rem;
  /* height: 20rem; */
}

.bg-text {
  font-size: 1.5em;
}

img {
  border-radius: 5px 5px 0 0;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
  padding-bottom: 10rem;
}

.logo {
  display: block;
  position: relative;
  width: 20rem;

  /* padding: 16px 0; */

  /* margin: 1rem 1rem; */
  /* left: 10%; */
  /* top: 15%; */
  color: var(--light-grey);
  font-size: 20px;
}

.landing {
  display: flex;
  width: 100%;
  padding: 4rem;
  font-family: 'Montserrat';
  font-style: normal;
  height: inherit;
}

.landing .row {
  max-width: 60%;
  margin-top: 0;
}

.landing h1 {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: #36C3DF;
  font-family: 'Montserrat';
}

.landing p {
  font-size: 1.125rem;
  color: #EEEEEE;
  font-family: "Open Sans", sans-serif;
}

.landing .btn-container {
  display: flex;
  gap: 11px;
  margin: 2rem 0;
  align-items: center;
}

.menu-logo-footer {
  margin: 4rem 1rem;
  max-height: 5rem;
  /* margin-left: 20px; */
}

.menu-logo {
  max-height: 3rem;
  /* margin-left: 20px; */
  cursor: pointer;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;

  justify-content: center;
  padding: 1rem 4rem;
  margin: 0;
  list-style: none;
}

.flex-container-button {
  display: flex;
  flex-flow: row wrap;

  /* align-items: flex-start; */
  justify-content: flex-start;
  /* justify-content: space-evenly; */
  padding: 0;
  margin: 0;
  list-style: none;
}

.flex-item {
  display: inline-block;
  margin: 0 auto;
  padding: 1rem 3rem;
  /* width: 200px; */
  height: 10rem;
  text-align: center;
  line-height: 100px;
}

.flex-item img {
  vertical-align: middle;
  /* height: 50%; */
  height: 4rem;
}

.flex-button {
  padding: 1rem 1rem;
  height: 5rem;
  margin-top: 1rem;
}

.our-partners-container {
  margin: 1.5rem;
  justify-content: center;
}

/* .flex-button button:hover,
button:focus {
  padding: 0.8rem 0.8rem;
  color: var(--primary-color);
  background: transparent;
  border: 0.2rem solid var(--primary-color);
} */

.align-center {
  display: flex;
  align-items: center;
}

.vert-align-middle {
  vertical-align: middle;
  margin: auto;
}

.bgimg-1 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.bgimg-1 img {
  width: 100%;
  overflow: hidden;
  background-size: cover;
  /* margin: -75px 0 0 0; */
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 10%;
  /* left: 50%;
  transform: translate(-50%, -50%); */
}


.float-text {
  margin-bottom: 1rem;
  margin-left: 2vw;
  opacity: 0.7;
  width: 70%;
}

.img-container {
  position: relative;
  text-align: center;
  color: white;
}

.img-container img {
  width: 80%;
}

/* Solutions page */
.solution-bg {
  width: 100%;
  /* height: 80vh; */
  /* padding-bottom: 16rem; */
  margin-top: 1rem;
  min-height: 40rem;
  background-image: url('../../static/bg/bg-2.jpg');
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  opacity: 0.9;
}

.solution-elem {
  margin: auto;
  padding: 0 8px;
}

.width-100 {
  width: 100% !important;
}

.max-width-700px {
  max-width: 700px;
  margin: auto;
}

.min-height-row {
  min-height: 25rem;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* margin: 2rem 8rem; */
  /* margin: 2rem; */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.height-text-area {
  height: 6rem;
  resize: none;
}

.flex-container-2 {
  display: flex;
  flex-flow: row wrap;

  justify-content: space-between;
  align-items: flex-end;
  /* padding: 1rem 4rem; */
  /* margin: 0; */
  list-style: none;
}

.flex-item-2 {
  display: inline-block;
  /* margin: 0 auto; */
  /* padding: 1rem 3rem; */
  /* width: 200px; */
  height: 150px;
  text-align: center;
  line-height: 100px;
}

.quote-text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: center;
  margin-top: 2rem;
}

.section-lbl {
  color: #444444;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem !important;
  line-height: 3.188rem;
}

.section-sub-lbl {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 36px;
  color: #176BA0;
  display: block;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 1rem;
  max-width: 100vw;
}

.section-description {
  height: 42px;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  text-align: center;
  color: #444444;
}

.ddos-attack-text {
  width: 90vw;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 36px;
  text-align: center;
  color: #444444;
}

.max-width-70vw {
  max-width: 70vw !important;
}

.industry-focus-container {
  justify-content: center;
  background-color: #F6F6F6 !important;
}

.industry-focus-btn-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  justify-items: center;
  margin: 2rem 0;
  gap: 1.375rem;
  max-width: 100vw;
}

.industry-focus-btn-item {
  width: 15rem;
  height: 20rem;
  background: linear-gradient(180deg, #2D3745 0%, #222222 100%), #EEEEEE;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.industry-focus-btn-item:focus {
  outline: none;
  background-color: transparent;
}

.industry-focus-btn-item img {
  width: 50%;
  height: 50%;
  margin: auto auto 0 auto;
}

.industry-focus-btn-item span {
  width: inherit;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 2.125rem;
  color: #EEEEEE;
  margin: auto;
  display: block;
  text-align: center;
}

.protection-monitoring-suite-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;
  gap: 1rem;
}

.protection-monitoring-suite {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: center;
  gap: 2rem;
  padding: 5rem 1rem;
}

.protection-monitoring-suite .suite-lbl {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2rem;
  text-align: center;
  color: #FFFFFF;
}

.protection-monitoring-suite .suite-sub {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 2rem;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
}

.protection-suite {
  background-image: url('../../static/protection_suite_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.monitor-suite {
  background-image: url('../../static/monitor_suite_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
	background: #000000;
  margin: 0 auto;
}

#particlesjs {
  width: 100%;
  height: 100%;
  position: absolute;
	background: #000000;
  margin: 0 auto;
}

.why-microsec-container {
  display: flex;
  flex-direction: column;
  background-image: url('../../static/why_microsec_icon.png');
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  gap: 1rem;
}

.why-microsec-container .lbl {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  color: #FFFFFF;
}

.why-microsec-container .sub-lbl {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1rem;
  text-align: center;
  color: #36C3DF;
}

.why-microsec-container .description {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: #FFFFFF;
  max-width: 1437px;
  width: 90vw;
}

.customer-testimonial-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: inherit;
  gap: 6.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.customer-testimonial-sub-container .lbl {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 3rem;
  color: #FFFFFF;
}

.customer-testimonial-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  max-width: 69.063rem;
}

.customer-testimonial-sub-container .sub-lbl {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 2rem;
  color: #EEEEEE;
  max-width: 50rem;
}

.customer-testimonial-sub-container .description {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 2rem;
  color: #36C3DF;
}

.schedule-demo-container {
  display: flex;
}

.schedule-demo-right-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  padding: 0 147px 0 71px;
}

.schedule-demo-right-section .lbl {
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 1.8rem;
  line-height: 2.5rem;
  color: #333333;
}

.schedule-demo-right-section .sub-lbl {
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 1.2rem;
  line-height: 2rem;
  color: #176BA0;
}

.schedule-demo-container .form-group {
  display: flex;
}

.schedule-demo-container form input {
  padding: 0 1rem;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #888888;
  width: calc(100% - 260px);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid;
}

.schedule-demo-container form input::placeholder {
  padding: 0 1rem;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.375rem;
}

input {
  height: 3.75rem;
}

textarea {
  height: 15rem;
  resize: none;
}

input:focus {
  outline: none;
}

.our-partner-im-container {
  display: grid;
  grid-template-columns: repeat(8, auto);
  align-items: center;
  justify-items: center;
  margin: 1rem 0;
  gap: 1rem;
  max-width: 70vw;
}

.our-partner-im-container img{
  max-width: 100%;
}

.cyan-txt {
  color: #36C3DF !important;
}

.blue-txt {
  color: #1F6DFF !important;
}

.bold-blue-text {
  color: #176BA0 !important;
}

.white-txt {
  color: #FFFFFF !important;
}

.red-txt {
  color: #DC3545 !important;
}

.green-txt {
  color: #198754 !important;
}

.fnt-weight-700 {
  font-weight: 700 !important;
}

.vh-45 {
  height: 27.375rem;
}

.vh-60 {
  height: 40rem;
}

.vh-50 {
  height: 30.313rem;
}

.wild-sanda-bg {
  background-color: #F6F6F6 !important;
}

.black-bg {
  background-color: #000000 !important;
}

.flex-row {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.col-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Styles of menu dropdown */
.menu-dropdown {
  display: none;
}

.menu-dropdown ul {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}

.menu-dropdown li {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 2.813rem;
  text-transform: uppercase;
  color: #EEEEEE;
  list-style: none;
}

.menu-dropdown a:hover {
  color: #36C3DF !important;
}

#industryItem:hover #industryMenu, #platformItem:hover #platformMenu, #companyItem:hover #companyMenu {
  top: 86px;
  position: absolute;
  min-width: fit-content;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
}

#topRequestBtn {
  margin: 0.8rem 0;
}
/* End styles of menu dropdown */

.font-18 {
  font-size: 1.125rem !important;
}

.font-24 {
  font-size: 1.5rem !important;
  line-height: 2.25rem !important;
}

.font-20 {
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
}

.font-montserrat-32 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 3.438rem;
}

.font-montserrat {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
}

a:hover {
  color: inherit;
}

.discover-section {
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4rem;
  padding-right: 4rem;
}

.discover-section h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 4.875rem;
  color: #36C3DF;
  margin: 0;
}

.discover-section button {
  margin: 6.188rem auto 0 auto;
}

/* key benefits styles */
.key-benefits-container {
  padding: 0 9.75rem;
}

.key-benefits-items {
  display: grid;
  grid-template-columns: 15rem 15rem 15rem;
  gap: 1.25rem;
  justify-content: center;
}

.key-benefits-item {
  padding: 1rem;
  height: 15rem;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.key-benefits-item img {
  height: 60%;
}

.key-benefits-item h1 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #333333;
}
/* end key benefits style */

/* feature overview styles */
.feature-overview-content {
  background: #FFFFFF;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  display: grid;
  text-align: left;
  grid-template-columns: 36% 32% 32%;
}

.feature-overview-content h1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 2.125rem;
  color: #36C3DF;
}

.feature-overview-content li {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 2rem;
  color: #222222;
}

.feature-overview-content ul{
  padding: 0 0 0 3rem;
}

.feature-overview-content-item {
  padding: 0 3rem;
}

.feature-overview-content-item:not(:last-child) {
  border-right: 1px solid #CCCCCC;
}
/* end feature overview styles */

@media (max-width: 1280px) {
  .schedule-demo-container .form-group {
    display: grid;
    grid-template-columns: 100%;
  }

  .schedule-demo-container form input {
    min-width: 20rem;
    margin-bottom: 0.5rem;
  }
    
}

@media (max-width: 1024px) {
  .menu-logo {
    max-height: 2.5rem;
  }

  .our-partner-im-container {
    grid-template-columns: repeat(4, auto);
  }

  .industry-focus-btn-container {
    grid-template-columns: auto auto;
  }

  .landing {
    justify-content: center;
    padding: 2.5rem 0;
    margin-left: auto;
    margin-right: auto;
  
    color: var(--light-grey);
    font-size: 1em;
    text-align: center;
  
  }

  .landing h1 {
    font-size: 2rem;
    line-height: 2rem;
  }

  .landing p {
    font-size: 1.2rem;
  }

  .landing .btn-container {
    justify-content: center;
  }

  .flex-container-button {
    justify-content: space-evenly;
  }

  .logo {
    position: relative;
    margin: auto;
    width: 25rem;
    top: 15%;
    padding-top: 32px;
    margin-top: 2rem;
    font-size: 16px;
  }

  .list-bullet {
    padding-left: 1rem;
  }

  .schedule-demo-container {
    margin: 2rem 0;
  }

  .side-img {
    display: none;
  }

  .customer-testimonial-container {
    gap: 4rem;
  }
  
  .customer-testimonial-sub-container {
    gap: 3rem;
  }

  .customer-testimonial-sub-container .lbl {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }

  .customer-testimonial-sub-container .sub-lbl {
    font-size: 0.75rem;
    line-height: 1.75rem;
  }

  .customer-testimonial-sub-container .description {
    font-size: 0.75rem;
    line-height: 1.75rem;
  }

  .key-benefits-container {
    padding: 0 6rem;
  }

  .content-container {
    grid-template-columns: 1fr;
  }

  .content-container img {
    order: -1;
  }

  .content-container-port {
      grid-template-columns: 100%;
  }

  .content-container-port img {
    display: none;
  }

  .feature-overview-content {
    grid-template-columns: 100%;
  }
}

@media (max-width: 800px) {
  .vh-40 {
    height: 25rem;
  }
}

@media (max-width: 768px) {
  :root {
    font-size: 12px;
  }

  .solution-bg {
    min-height: 35rem;
  }

  .bg-text {
    font-size: 1em;
  }

  .height-text-area {
    height: 8rem;
  }

  .min-height-row {
    min-height: 40rem;
  }

  .section .subtitle {
    width: 90%;
  }

  .section-alt .subtitle {
    width: 90%;
  }

  .logo {
    position: relative;
    margin: auto;
    width: 25rem;
    top: 15%;
    padding-top: 32px;
    margin-top: 2rem;
    font-size: 16px;
  }

  .protection-monitoring-suite-container {
    grid-template-columns: 100%;
  }

  .flex-button {
    padding: 1rem 1rem;
    width: 25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  button {
    width: 12rem;
    height: 4rem;
  }

  .container {
    padding-bottom: 2rem;
  }

  .solution-elem {
    margin: 4rem 0rem;
  }

  .flex-item {
    /* padding: 1rem 4rem; */
    height: 8rem;
  }

  .flex-item img {
    padding: 1rem 1rem;
    height: 5.5rem;
  }

  /* .vh-70 {
    height: auto;
  } */

  .vh-40 {
    height: 50rem;
  }

  .list-bullet {
    padding-left: 0.8rem;
  }
  
  .key-benefits-items {
    grid-template-columns: 15rem 15rem;
  }

  .discover-section h1 {
    font-size: 3rem;
    line-height: 4rem;
  }
}

@media (max-width: 425px) {
  .on-mobile {
    display: block;
  }
  
  .on-desktop {
    display: none;
  }

  .our-partner-im-container {
    grid-template-columns: repeat(2, auto);
  }

  .max-width-70vw {
    max-width: 100vw !important;
    width: 100% !important;
  }

  .key-benefits-items {
    grid-template-columns: 15rem;
  }

  .landing .btn-container {
    flex-direction: column;
  }

  .industry-focus-btn-container {
    grid-template-columns: auto;
  }
}